<template>
  <div>
  <b-row class="card mt-5">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-pen"></i> Filtros
        </h5>
      </div>

      <div class="w-100 p-3">
        <b-row>
          <b-col lg="6">
            <b-form-group label="Nome do cliente">
              <b-form-input v-model="filters.name"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="3">
            <b-form-group label="Estado">
              <b-form-select @change="getCities" v-model="filters.province" :options="provinces"></b-form-select>
            </b-form-group>
          </b-col>

          <b-col lg="3">
            <b-form-group label="Cidade">
              <b-form-select :disabled="!cities.length" v-model="filters.city" :options="cities"></b-form-select>
            </b-form-group>
          </b-col>

          <b-col lg="4" class="align-btn">
            <b-button variant="info" @click="getAll(true)" class="mr-2">Pesquisar</b-button>
            <b-button variant="danger" @click="clearFilters">Limpar</b-button>
          </b-col>
        </b-row>
      </div>
    </b-row>

    <b-row class="card mt-5">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-users"></i> Clientes Cadastrados
        </h5>
      </div>

      <div class="w-100 p-3">
        <b-button variant="primary" :to="{ name: 'clients-create' }">
          <i class="fas fa-plus"></i> Adicionar novo cliente
        </b-button>
      </div>

      <div class="w-100 p-3">
        <b-table
        responsive
        bordered
        :current-page="currentPage"
        :per-page="perPage"
        :busy="load"
        :items="clients"
        :fields="columnsTableClients">
          <template #table-busy>
            <div class="text-center text-success my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Carregando...</strong>
            </div>
          </template>
          <template v-slot:cell(actions)="data">
            <b-button
            v-b-tooltip.hover
            title="Editar cliente"
            :to="{ name: 'client-edit', params: { id: data.item.id } }"
            size="sm"
            class="mr-1"
            variant="warning">
              <i class="fas fa-pen"></i>
            </b-button><!--
            <b-button
            v-b-tooltip.hover
            title="Excluir cliente"
            :to="{ name: data.id }"
            size="sm"
            variant="danger">
              <i class="fas fa-trash"></i>
            </b-button> -->
          </template>
        </b-table>
      </div>

      <div class="w-100 py-3" v-if="totalRows > 15">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>
    </b-row>
  </div>
</template>

<script>
import ClientService from '@/Services/ClientService';
import HelperService from '@/Services/HelperService';
import MessagesMixin from '@/mixins/messages-mixin';
import QuestionMixin from '@/mixins/question-mixin';
import ErrorMixin from '@/mixins/error-mixin';

export default {
  data() {
    return {
      columnsTableClients: ClientService.getFieldsTableClients(),
      clients: {},
      provinces: {},
      load: true,
      totalRows: 0,
      currentPage: 1,
      perPage: 15,
      cities: {},
      filters: {
        name: '',
        province: '',
        city: '',
      },
      payload: {
        name: '',
        address: {
          street: '',
          neighborhood: '',
          number: '',
          zip_code: '',
          city: '',
          province: '',
          complement: '',
        },
      },
    };
  },
  mixins: [MessagesMixin, QuestionMixin, ErrorMixin],
  methods: {
    async getAll(hasFilter) {
      const filters = { ...this.filters };

      try {
        const { data } = hasFilter ? await ClientService.getList(filters) : await ClientService.getList();
        this.totalRows = data.length;
        this.clients = data.map((item) => ({
          id: item.id,
          name: item.name,
          street: item.addresses.length > 0 ? item.addresses[0].street : '',
          neighborhood: item.addresses.length > 0 ? item.addresses[0].neighborhood : '',
          city: item.addresses.length > 0 ? item.addresses[0].city.name : '',
          province: item.addresses.length > 0 ? item.addresses[0].city.province.initial : '',
        }));
      } catch (error) {
        console.log(error);
      }
      this.load = false;
    },

    clearFilters() {
      this.getAll(false);
      Object.keys(this.filters).forEach((key) => {
        this.filters[key] = '';
      });
    },

    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.save();
    },

    async getProvinces() {
      try {
        const { data } = await HelperService.getProvinces();
        this.provinces = data.map((item) => ({
          value: item.id,
          text: item.name,
        }));
      } catch (error) {
        console.log(error);
      }
    },

    async getCities() {
      const params = {
        province_id: this.filters.province,
      };

      try {
        const { data } = await HelperService.getCities(params);
        this.cities = data.map((item) => ({
          value: item.id,
          text: item.name,
        }));
      } catch (error) {
        console.log(error);
      }
    },

    reset() {
      this.payload.name = '';
      this.payload.address.street = '';
      this.payload.address.neighborhood = '';
      this.payload.address.number = '';
      this.payload.address.zip_code = '';
      this.payload.address.city = '';
      this.payload.address.province = '';
      this.payload.address.complement = '';
      this.$v.payload.$reset();
    },

    async save() {
      this.$v.payload.$touch();
      if (!this.$v.payload.$invalid) {
        try {
          await ClientService.save(this.payload);
          this.messageSuccess('Cliente cadastrado com sucesso');

          this.$nextTick(() => {
            this.$refs['create-client'].hide();
          });

          this.getAll();
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  created() {
    this.getAll();
    this.getProvinces();
  },
};
</script>
