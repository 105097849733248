import http from '@/http';

export default class HelperService {
  static getProvinces() {
    return http.get('provinces');
  }

  static getCities(params) {
    return http.get('cities', { params });
  }

  static async getCep(cep) {
    const { data } = await http.get(`https://viacep.com.br/ws/${cep}/json/`);
    console.log(data);
  }
}
